import { createContext, useContext, useState } from 'react';
import ConfirmDialog from './ConfirmDialog';

const ConfirmContext = createContext();

export const useConfirm = () => useContext(ConfirmContext);

export default function ConfirmProvider({ children }) {
  const [dialog, setDialog] = useState({ isOpen: false });
  function confirm(options) {
    setDialog({ ...dialog, ...options, isOpen: true });
  }
  function closeDialog() {
    setDialog({ ...dialog, isOpen: false });
  }
  return (
    <ConfirmContext.Provider value={{ confirm, closeDialog }}>
      {children}
      <ConfirmDialog {...dialog} handleClose={closeDialog} />
    </ConfirmContext.Provider>
  );
}
