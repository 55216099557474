import { UserType as roles } from '@prisma/client';

const actions = {
  MANAGE_USERS: [roles.ADMIN],
  MANAGE_CAPTURES: [roles.ADMIN],
  MANAGE_SUBJECTS: [roles.ADMIN],
  MANAGE_SCORE_SYSTEMS: [roles.ADMIN],
  MANAGE_SCHOOLS: [roles.SUPERADMIN],
  MANAGE_SCHOOL: [roles.ADMIN, roles.SUPERADMIN],
  MANAGE_TRUSTS: [roles.TRUST_ADMIN, roles.TRUST_USER],
  MANAGE_TEMPLATES: [roles.ADMIN],
  MANAGE_CLASSES: [roles.ADMIN],
  READ_NOTIFICATIONS: [roles.TEACHER, roles.ADMIN],
  UPLOAD_SUPPORT: [roles.TEACHER, roles.ADMIN],
  VIEW_SUPPORT: [roles.TEACHER, roles.ADMIN],
  IMPORT_RESULTS: [roles.ADMIN],
  SYNC_USERS: [roles.ADMIN, roles.SUPERADMIN],
  MANAGE_SETTINGS: [roles.ADMIN],
};

const hasPermission = (user, action) => {
  const userGroups = user.signInUserSession.idToken.payload['cognito:groups'];
  if (!userGroups) return false;
  if (userGroups.includes(roles.SUPERADMIN)) return true;
  return userGroups.some((role) => action.includes(role));
};

const hasRole = (user, permittedRoles) => {
  const userGroups = user.signInUserSession.idToken.payload['cognito:groups'];
  if (!userGroups) return false;
  return userGroups.some((role) => permittedRoles.includes(role));
};

export { roles, actions, hasRole, hasPermission };
