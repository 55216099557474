import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export default function ConfirmDialog({
  isOpen,
  handleClose,
  onSubmit,
  title,
  desc,
  confirmText = 'Delete',
}) {
  const onClose = (event, reason) => {
    switch (reason) {
      case 'escapeKeyDown':
        return;
      case 'backdropClick':
        return;
      default:
        handleClose();
    }
  };
  return (
    <Dialog maxWidth="xs" open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Typography component="p">{desc}</Typography>
        <Typography component="p" style={{ fontWeight: 700, marginTop: 10 }}>
          This action cannot be reversed.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          color="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          variant="outlined"
          style={{ borderColor: 'crimson', color: 'crimson' }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
