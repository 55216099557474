import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    text: {
      primary: '#525252',
    },
  },
});

export default theme;
