import Link from 'next/link';
import Image from 'next/legacy/image';
import { makeStyles } from '@material-ui/core/styles';
import getProductVariant from '../utils/getProductVariant';

const useStyles = makeStyles({
  logo: {
    flexGrow: 1,
    display: 'flex',
  },
  logoImage: {
    cursor: 'pointer',
  },
});

export default function Logo({ width = 150, height = 50 }) {
  const classes = useStyles();
  const { logo } = getProductVariant();
  return (
    <div className={classes.logo}>
      <Link href="/" passHref legacyBehavior>
        <Image
          src={logo}
          width={width}
          height={height}
          objectFit="contain"
          alt="site logo"
          className={classes.logoImage}
        />
      </Link>
    </div>
  );
}
