import {
  LOGIN_DISABLED,
  USER_NOT_ACTIVATED,
  USER_NOT_FOUND,
} from '../constants';

export default function getSignInFailureReason(error) {
  let errorMessage = error;
  if (error.includes('USER_NOT_FOUND')) {
    errorMessage = USER_NOT_FOUND;
  } else if (error.includes('USER_NOT_ACTIVATED')) {
    errorMessage = USER_NOT_ACTIVATED;
  } else if (error.includes('LOGIN_DISABLED')) {
    errorMessage = LOGIN_DISABLED;
  }
  return errorMessage;
}
