import Head from 'next/head';
import Router from 'next/router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import AuthProvider from '../components/AuthProvider';
import ConfirmProvider from '../components/ConfirmProvider';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import Layout from '../components/Layout';
import theme from '../styles/theme';
import '../styles/globals.css';
import '../lib/amplify';
import getProductVariant from '../utils/getProductVariant';
import { languageResources } from '../constants';
import 'globalthis/polyfill';

function onError(error) {
  if (error.status === 401) {
    Router.push('/login').then(() =>
      toast('Please log in to continue', {
        type: 'warning',
        toastId: 'login-required',
      })
    );
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
  queryCache: new QueryCache({ onError }),
  mutationCache: new MutationCache({ onError }),
});

export default function MyApp({ Component, pageProps }) {
  const { title, favicon } = getProductVariant();
  if (typeof window !== 'undefined') {
    i18next.use(initReactI18next).init({
      lng: window.localStorage.getItem('language') || 'en',
      resources: languageResources,
      interpolation: {
        escapeValue: false,
      },
    });
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        {/* TODO: add meta description */}
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="icon" href={favicon} />
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <ConfirmProvider>
            <AuthProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Layout>
                  <Component {...pageProps} />
                  <ToastContainer position="bottom-right" limit={3} />
                </Layout>
              </MuiPickersUtilsProvider>
            </AuthProvider>
          </ConfirmProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
}
