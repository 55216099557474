const TRACIWR = 'dashboard.traciwr.com';
const EDRIAN = 'dashboard.edrian.co.uk';
const TLEVELTRACKER = 'dashboard.tleveltracker.com';
const SMIDPRIMARY = 'dashboard.smidreport.com';

export default function getProductVariant(
  canManageTemplates = false,
  canImportResults = false,
  isSchoolAdmin = false
) {
  const hostname = getHostname();
  const userGuide = getUserGuide(
    canManageTemplates,
    canImportResults,
    isSchoolAdmin,
    hostname
  );
  switch (hostname) {
    case SMIDPRIMARY:
      return {
        logo: '/logo-smidprime.png',
        favicon: '/favicon.ico',
        title: 'SMID Primary',
        url: hostname,
        email: 'primarysupport@smidreport.com',
        folderHexColour: '#429f9f',
        microsoftClientId: '8d718629-96d0-4d2a-96f4-50b16b4d2f0f',
        userGuide,
      };
    case TLEVELTRACKER:
      return {
        logo: '/logo-tleveltracker.png',
        favicon: '/favicon-tlt.png',
        title: 'T Level Tracker',
        url: hostname,
        email: 'info@tleveltracker.com',
        folderHexColour: '#f06944',
        microsoftClientId: 'e16a0069-fe96-470f-9ae7-07ef122f9890',
        userGuide,
      };
    case EDRIAN:
      return {
        logo: '/logo-edrian.png',
        favicon: '/favicon-edrian.png',
        title: 'Edrian',
        url: hostname,
        email: 'info@edrian.co.uk',
        folderHexColour: '#06549f',
        microsoftClientId: '30a05c5f-b708-42c2-a776-de8cb174a561',
        userGuide,
      };
    default:
      return {
        logo: '/logo-traciwr.png',
        favicon: '/favicon-traciwr.png',
        title: 'Traciwr',
        url: hostname,
        email: 'info@traciwr.com',
        folderHexColour: '#429f9f',
        microsoftClientId: 'f8cf68ff-275d-49fe-a526-bbaf3533936a',
        userGuide,
      };
  }
}

const ADMIN_TEMPLATE_BUILDER_IMPORT_RESULTS =
  'ADMIN_TEMPLATE_BUILDER_IMPORT_RESULTS';
const ADMIN_TEMPLATE_BUILDER_ONLY = 'ADMIN_TEMPLATE_BUILDER_ONLY';
const ADMIN_IMPORT_RESULTS_ONLY = 'ADMIN_IMPORT_RESULTS_ONLY';
const TEACHER_DEFAULT = 'TEACHER_DEFAULT';
const ADMIN_DEFAULT = 'ADMIN_DEFAULT';

const userGuidesByVariant = {
  [TRACIWR]: {
    [ADMIN_TEMPLATE_BUILDER_IMPORT_RESULTS]:
      'https://traciwrpublic.s3.eu-west-2.amazonaws.com/guides/ADMIN+USER+GUIDE+(Template+Builder+%26+Import+Results+Allow)+-+Traciwr.pdf',
    [ADMIN_TEMPLATE_BUILDER_ONLY]:
      'https://traciwrpublic.s3.eu-west-2.amazonaws.com/guides/ADMIN+USER+GUIDE+(Template+Builder+Allow)+-+Traciwr.pdf',
    [ADMIN_IMPORT_RESULTS_ONLY]:
      'https://traciwrpublic.s3.eu-west-2.amazonaws.com/guides/ADMIN+USER+GUIDE+(Import+Results+Allow)+-+Traciwr.pdf',
    [TEACHER_DEFAULT]:
      'https://traciwrpublic.s3.eu-west-2.amazonaws.com/guides/TEACHER+USER+GUIDE+-+Traciwr.pdf',
    [ADMIN_DEFAULT]:
      'https://traciwrpublic.s3.eu-west-2.amazonaws.com/guides/ADMIN+USER+GUIDE+-+Traciwr.pdf',
  },
  [EDRIAN]: {
    [ADMIN_TEMPLATE_BUILDER_IMPORT_RESULTS]: '',
    [ADMIN_TEMPLATE_BUILDER_ONLY]:
      'https://traciwrpublic.s3.eu-west-2.amazonaws.com/guides/ADMIN+USER+GUIDE+(Template+Builder+Allow)+-+Edrian.pdf',
    [ADMIN_IMPORT_RESULTS_ONLY]: '',
    [TEACHER_DEFAULT]: '',
    [ADMIN_DEFAULT]:
      'https://traciwrpublic.s3.eu-west-2.amazonaws.com/guides/ADMIN+USER+GUIDE+(NO+Template+Allow)+-+Edrian.pdf',
  },
  [SMIDPRIMARY]: {
    [ADMIN_TEMPLATE_BUILDER_IMPORT_RESULTS]:
      'https://traciwrpublic.s3.eu-west-2.amazonaws.com/guides/ADMIN+USER+GUIDE+(Template+Builder+%26+Import+Results+Allow)+-+SMID+Primary.pdf',
    [ADMIN_TEMPLATE_BUILDER_ONLY]:
      'https://traciwrpublic.s3.eu-west-2.amazonaws.com/guides/ADMIN+USER+GUIDE+(Template+Builder+Allow)+-+SMID+Primary.pdf',
    [ADMIN_IMPORT_RESULTS_ONLY]:
      'https://traciwrpublic.s3.eu-west-2.amazonaws.com/guides/ADMIN+USER+GUIDE+(Import+Results+Allow)+-+SMID+Primary.pdf',
    [TEACHER_DEFAULT]:
      'https://traciwrpublic.s3.eu-west-2.amazonaws.com/guides/TEACHER+USER+GUIDE+-+SMID+Primary.pdf',
    [ADMIN_DEFAULT]:
      'https://traciwrpublic.s3.eu-west-2.amazonaws.com/guides/ADMIN+USER+GUIDE+-+SMID+Primary.pdf',
  },
  [TLEVELTRACKER]: {
    [ADMIN_TEMPLATE_BUILDER_IMPORT_RESULTS]: '',
    [ADMIN_TEMPLATE_BUILDER_ONLY]: '',
    [ADMIN_IMPORT_RESULTS_ONLY]: '',
    [TEACHER_DEFAULT]: '',
    [ADMIN_DEFAULT]: '',
  },
};

function getUserGuide(
  canManageTemplates = false,
  canImportResults = false,
  isSchoolAdmin = false,
  variant
) {
  const userGuides = userGuidesByVariant[variant];

  if (!userGuides) return null;

  if (!isSchoolAdmin) return userGuides[TEACHER_DEFAULT];

  let key = ADMIN_DEFAULT;

  if (canManageTemplates && canImportResults) {
    key = ADMIN_TEMPLATE_BUILDER_IMPORT_RESULTS;
  } else if (canManageTemplates) {
    key = ADMIN_TEMPLATE_BUILDER_ONLY;
  } else if (canImportResults) {
    key = ADMIN_IMPORT_RESULTS_ONLY;
  }

  return userGuides[key] ?? userGuides[ADMIN_DEFAULT];
}

function getHostname() {
  if (process.env.NODE_ENV === 'development') return TRACIWR;

  return typeof window !== 'undefined' && window.location.hostname
    ? window.location.hostname
    : '';
}
