import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { publicRoutes } from '../constants';
import * as msal from '@azure/msal-browser';
import getProductVariant from '../utils/getProductVariant';
import getSignInFailureReason from '../utils/getSignInFailureReason';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

function generatePassword(
  length = 20,
  wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
) {
  const password = Array.from(crypto.getRandomValues(new Uint32Array(length)))
    .map((x) => wishlist[x % wishlist.length])
    .join('');

  return /\d/.test(password) && /[A-Z]/.test(password) && /[a-z]/.test(password)
    ? password
    : generatePassword();
}

function deleteAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie =
      name +
      `=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${window.location.hostname}`;
  }

  if (window.localStorage) {
    const templateId = window.localStorage.getItem('templateId');
    const activeFolderId = window.localStorage.getItem('activeFolderId');
    window.localStorage.clear();
    window.localStorage.setItem('templateId', templateId);
    window.localStorage.setItem('activeFolderId', activeFolderId);
  }
}

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  globalThis.setLoginError = (error) => setError(error);

  async function refreshToken() {
    try {
      const currentUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setUser(currentUser);
    } catch {
      router.push({
        pathname: '/login',
        query: {
          ...(window.location.pathname !== '/' && {
            redirect: window.location.pathname,
          }),
        },
      });
    }
  }

  useEffect(() => {
    if (publicRoutes.includes(router.pathname)) return;

    refreshToken();

    const refreshInterval = setInterval(refreshToken, 900_000); // 15 minutes

    return () => clearInterval(refreshInterval);
  }, [router.pathname]);

  async function login(email, password, redirectUri = '/') {
    setError(null);
    setLoading(true);
    try {
      deleteAllCookies();
      const user = await Auth.signIn(email, password);
      if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        router.push(`/account/confirm?email=${email}&_code=${password}`);
        return;
      }
      setUser(user);
      router.push(redirectUri);
    } catch (e) {
      const error = getSignInFailureReason(e.message);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function loginWithMicrosoft() {
    setLoading(true);
    setError(null);

    const { microsoftClientId } = getProductVariant();

    const msalInstance = new msal.PublicClientApplication({
      auth: {
        clientId: microsoftClientId,
      },
    });
    await msalInstance.initialize();
    msalInstance.setActiveAccount(null);

    async function authenticateWithMicrosoftToken(email, token) {
      try {
        const cognitoUser = await Auth.signIn(email);
        const user = await Auth.sendCustomChallengeAnswer(cognitoUser, token);
        setUser(user);
        setLoading(false);
        router.push('/');
      } catch (e) {
        await handleMicrosoftSsoError(e);
      }
    }

    async function handleMicrosoftSsoError(e) {
      if (e.code === 'UserLambdaValidationException') {
        const error = getSignInFailureReason(e.message);
        setError(error);
        setLoading(false);
        msalInstance.setActiveAccount(null);
        return;
      }

      if (e.code === 'UsernameExistsException') {
        // ignore cognito signup error, attempt to proceed with sign in
        const user = msalInstance.getActiveAccount();
        const email =
          user.idTokenClaims['email'] ??
          user.idTokenClaims['preferred_username'];
        await authenticateWithMicrosoftToken(email, user.idToken);
        return;
      }

      console.log('Sign in with Microsoft failed:', error);
      setError('Sign in with Microsoft failed, please try again');
    }

    const params = {
      scopes: ['user.read', 'email'],
    };

    try {
      await msalInstance.loginPopup(params).then((response) => {
        msalInstance.setActiveAccount(response.account);
      });
      const user = await msalInstance.acquireTokenSilent({
        ...params,
        account: msalInstance.getActiveAccount(),
      });
      const email =
        user.idTokenClaims['email'] ?? user.idTokenClaims['preferred_username'];
      await Auth.signUp({
        username: email,
        password: generatePassword(),
        attributes: {
          given_name: user.idTokenClaims['given_name'],
          family_name: user.idTokenClaims['family_name'],
          'custom:msal_id_token': user.idToken,
        },
      });
      await authenticateWithMicrosoftToken(email, user.idToken);
    } catch (e) {
      await handleMicrosoftSsoError(e);
    }

    setLoading(false);
  }

  async function loginWithGoogle() {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  }

  async function logout() {
    await Auth.signOut();
    setUser(null);
    router.push('/login');
  }

  const values = useMemo(
    () => ({
      user,
      login,
      logout,
      error,
      loading,
      loginWithMicrosoft,
      loginWithGoogle,
    }),
    [user, error, loading]
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}
